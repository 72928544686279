<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; padding-right: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 0.5em;") CÓMO UTILIZARLO
          p(style="margin-bottom: 0.5em;") #[strong IPS propio] lo compararemos con los #[strong IPS de los competidores] y en consecuencia podemos tener una aproximación del ticket medio que pagan los clientes en la competencia.
          div.mb-1
            card_4_-s-v-g_1
          p Comparando nuestra Marca (Marca 1) con la media de las dos marcas competidoras, vemos que nuestro IPS es inferior en un 3,5% y sobre todo tenemos mucha desviación de precios en postres (-15%) y en entrantes (-6%), por tanto podría plantearse una subida de precios en entrantes y postres.


  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; padding-right: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 0.5em;") HOW TO USE IT
          p(style="margin-bottom: 0.5em; padding-right: 50px;") We will compare #[strong our IPS with the IPS of the competitors]. Consequently, we can have an approximation of the average ticket that customers pay in the competition.
          div.mb-1
            card_4_-s-v-g_1_en
          p Comparing our Brand (Brand 1) with the average of the two competing brands, we see that our IPS is lower by 3.5%. Above all, we have a lot of price deviation in desserts (-15%) and entrees (-6% ); therefore, a price increase could be considered for starters and desserts
</template>

<script>
import Card_4_SVG_1 from "./assets/Card_4_SVG_1";
import Card_4_SVG_1_en from "@/components/guidedHelp/IPS/assets/Card_4_SVG_1_en";

export default {
  name: "Card_4",
  components: {Card_4_SVG_1_en, Card_4_SVG_1}
}
</script>

<style scoped>

</style>
